@import '../../styles/variables';

.footer {
    &__contact {
      background-color: $text-color;
      height: auto;
      padding: 16px 0;
  
      p {
        color: white;
        text-align: center;
        margin: 0;
        // height: 28px;
        font-weight: 400;
        max-width: 67.5em;
        margin: auto;
        line-height: 35px;
      }
      a {
        color: white;
        display:inline-block;
      }
    }
  
    &__credits {
      background-color: $title-color;
      min-height: 100px;
      display: flex;
      flex-direction: row;
      align-content: center;
  
      p {
        color: white;
        text-align: center;
        margin: 0;
        font-weight: 400;
        width: 100%;
        max-width: 67.5em;
        margin: auto;
      }
  
      a {
        color: white;
      }
    }
  }

  
@media only screen and (max-width: 1023px) {
    .footer {
      &__contact {
        padding: 15px;
        height: auto;
        p {
          height: auto;
          font-size: 14px;
          line-height: 22px;
        }
      }
  
      &__credits {
        padding: 15px;
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }