@import "../../styles/variables";

.faq {
  &__section {
    border-bottom: 2px solid #e4e4e4;
  }
}

.icon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 10px;
  transition: 0.2s ease;

  &.invert {
    transform: rotate(180deg);
  }
}

.collapse {
  &__button {
    font-size: $normal-text-size;
    color: $text-color;
    background-color: $bg-color;
    border: 0;
    padding: 20px 0;
    outline: 0;
    font-weight: 600;
    width: 100%;
    text-align: left;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: 0;
    }

    span {
      color: red;
    }
  }
}
