@import "../../styles/variables";

.govt {
  // display: flex;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto 382px auto;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  min-height: 160px;
  justify-content: space-evenly;
  background-color: white;

  &__goa-logo {
    height: 120px;
  }

  &__dte-logo {
    height: 100px;
  }

  &__title {
    text-align: center;
    font-weight: 500;
    color: black;

    &--small {
      font-size: 18px;
      @extend .govt__title;
      margin-bottom: 5px;
    }

    &--big {
      font-size: 22px;
      @extend .govt__title;
    }
  }
}

.gcet {
  min-height: 160px;
  background-color: #f4d761;
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__text {
    font-size: 24px;
    font-weight: 500;
    color: $title-color;
    display: flex;
    flex-direction: column;
  }
}

nav {
  background-color: $theme-color;
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;

  &__home {
    display: flex;
    flex-direction: row;

    &__icon {
      width: 25px;
      margin-right: 10px;
      opacity: 0.8;
    }

    &__title {
      color: white;
      font-size: 24px;
      font-weight: 500;
      line-height: 60px;
    }
  }

  &__item-container {
    ul {
      padding: 0;
      margin: 0;
    }
  }

  &__item {
    list-style-type: none;
    display: inline-block;
    // margin-left:24px;
    // margin-left:4px;

    a,span {
      color: white;
      font-size: $normal-text-size;
      font-weight: 500;
      line-height: 60px;
      padding: 0 12px;
      height: 60px;
      display: inline-block;
      box-sizing: border-box;
      cursor:pointer;

      &:hover,
      &.nav__item--active {
        background-color: $bg-color;
        color: black;
      }

      &:hover {
        border-left: 0.2px solid #dedfe3;
        border-right: 0.2px solid #dedfe3;
      }
    }
  }

  &__menu {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1130px) {
  .govt {
    &__goa-logo {
      height: 92px;
    }
    &__dte-logo {
      height: 80px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  #root {
    height: calc(100% - 328px);
  }
  .gcet {
    min-height: auto;
    padding: 40px 20px;
    justify-content: center;

    &__text {
      span:first-child() {
        display: none;
      }
    }
  }
  .eight-four-div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 30px;
  }
}

@media only screen and (max-width: 639px) {
  #root {
    height: calc(100% - 142px);
  }
  h1 {
    font-size: 24px;
    margin-bottom: 12px;

    &.application-form__title {
      margin-bottom: 20px;
      span {
        display: block;
        font-size: $mobile-text-size;
      }
    }
  }

  p {
    font-size: $mobile-text-size;
    line-height: 26px;
  }

  .govt {
    grid-template-columns: 46px auto;
    padding: 15px;
    min-height: auto;
    justify-content: left;
    // position: relative; z-index: 4;

    &__goa-logo {
      width: 46px;
      height: auto;
    }

    &__dte-logo {
      display: none;
    }

    &__title {
      &--small {
        font-size: 14px;
        text-align: left;
        line-height: normal;
        margin-bottom: 0;
        font-weight: normal;
      }

      &--big {
        font-size: 14px;
        text-align: left;
        line-height: normal;
      }
    }

    &__address {
      text-align: left;
      font-size: 14px;
      line-height: normal;
    }
  }

  .gcet {
    display: none;
    padding: 15px;

    &__text {
      font-size: 18px;
    }
  }

  .nav {
    height: 50px;
    align-items: center;
    position: relative;
    &__home {
      &__title {
        // display:none;
        font-size: 16px;
        line-height: 50px;
      }
    }
    &__item-container {
      // display:none;
      // background-color:$theme-color;
      background-color: white;
      width: 100%;
      height: auto;
      padding: 15px;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      z-index: 2;
      box-shadow: 0px 0px 13px rgba(66, 133, 244, 0.6);

      &.hide {
        display: none;
      }
    }

    &__item {
      display: block;
      // height:40px; line-height: 40px;

      a, span {
        color: black;
        height: 40px;
        line-height: 40px;
        font-weight: normal;

        &.nav__item--active {
          background-color: #c4d2ff;
        }
      }
    }

    &__menu {
      display: block;
      width: 25px;
      height: 25px;
    }
  }
}
