@import "../../styles/variables";

.document {
  &__preview {
    height: 70px;
    background: white;
    border-radius: 5px;
    border: 1px solid $border-color;
    display: none;
    padding: 0 12px 0 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    p {
      line-height: normal;
    }
  }

  &__detail-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
  }

  &__pdf-icon {
    height: 54px;
    background-color: white;
    border-radius: 3px;
    margin-right: 15px;
    border: 1px solid $border-color;
    min-width: 40px;
    cursor: pointer;
    background-image: url("../../images/view_icon.png");
    background-size: 40px;
    background-position: center;
    background-repeat: no-repeat;

    &.loading {
      position: relative;
      border: 0;
      background: none;
      &::before {
        content: "uploading file...";
        padding: 16px 10px 16px 45px;
        background-color: white;
        border-radius: 5px;
        position: absolute;
        left: 0;
        z-index: 2;
        min-width: 170px;
      }
      &:after {
        content: "";
        width: 30px;
        height: 30px;
        border-radius: 15px;
        position: absolute;
        z-index: 3;
        border: 3px solid #2fb60d;
        animation: loading 2s linear infinite;
        box-sizing: border-box;
        border-bottom-color: black;
        top: 12px;
        left: 7px;
      }
    }
  }

  &__name {
    font-size: 16px;
    line-height: normal;
    margin-bottom: -5px;
    color: $title-color;
    overflow: hidden;
    max-width: 170px;
    // word-break: break-all;
    height: 24px;
    position: relative;
    // text-transform: capitalize;
    text-overflow: ellipsis;

    // &::after{
    //   position: absolute; content: ". . . .";
    //   background: linear-gradient(270deg, #FFFFFF 44%, #FFFFFF 44%, rgba(255, 255, 255, 0.79) 69.78%, rgba(255, 255, 255, 0.36) 85.02%, rgba(255, 255, 255, 0) 89%);
    //   height:24px; right:0;top:0; width:50px; text-align: right;
    // }
  }
  &__type,
  &__size {
    font-size: 11px;
    padding-right: 10px;
    text-transform: uppercase;
    font-weight: 500;
    color: $title-color;
  }

  &__delete {
    height: 32px;
    width: 32px;
    border: 1px solid $border-color;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;

    &__icon {
      fill: red;
      height: 20px;
      width: 20px;
    }

    &:hover {
      background-color: #f9dede;
      border-color: red;
    }

    &.loading {
      position: relative;
      border: 0;
      padding: 6px;
      border-radius: 16px;
      &::after {
        border-radius: 16px;
        height: 32px;
        width: 32px;
        border: 2px solid red;
        border-bottom-color: black;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        box-sizing: border-box;

        animation: loading 2s infinite;
        -webkit-animation-timing-function: linear;
      }
    }
  }
}
