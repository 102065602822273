@import "./variables";

.stepper {
  height: 90px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid $block-bg-color;
  display: flex;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 80px;
  transition: 0.5s;
  // overflow: hidden;
  padding-top: 0.5px;

  &__title {
    margin-bottom: 20px;
    font-weight: 500;
  }

  &__step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    color: $text-color;
    cursor: pointer;
    height: 90px;
    position: relative;
    top: -1px;
    &:not(:last-child) {
      border-right: 1px solid #ededed;
    }
    &__number {
      text-transform: uppercase;
      font-size: 12px;
      line-height: normal;
      padding-top: 5px;
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &.showTick {
      background-color: $block-bg-color;
      overflow: visible;
      transition: 0.5s;
      .stepper__step__number,
      .stepper__step__title {
        color: white;
      }
      &::before {
        position: absolute;
        right: 20px;
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #2fb60d;
        background-image: url("../images//tick-white.png");
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &--active {
      background-color: $block-bg-color;
      // background-color:$twitter-bg-color;
      overflow: visible;
      transition: 0.5s;
      .stepper__step__number,
      .stepper__step__title {
        color: white;
      }

      &::after {
        position: absolute;
        bottom: -15px;
        left: 50%;
        margin-left: -25px;
        background-color: $block-bg-color;
        // background-color:$twitter-bg-color;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        transform: rotateZ(45deg);
        content: "";
        z-index: -1;
      }
    }

    &.disable {
      pointer-events: none;
    }

    &:not(.stepper__step--active):not(.showTick):hover {
      background-color: rgba(13, 12, 34, 0.05);
    }
  }
}

@media only screen and (max-width: 639px) {
  .stepper {
    overflow-x: scroll;
    // display:flex;
    height: 90px;
    margin-bottom: 20px;
    // overflow-y: visible;
    border: 0;

    &__title {
      margin-bottom: 5px;
    }

    &__step {
      padding: 0 8px;
      display: inline-flex;
      height: 67px;
      border: 1px solid $block-bg-color;
      border-left: 0;

      &:not(:last-child) {
        border-right-color: #ededed;
      }

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &__number {
        font-size: 12px;
        // margin-bottom:3px;
        padding: 0;
      }

      &__title {
        font-size: 12px;
        line-height: 15px;
        font-weight: normal;
        display: none;
      }

      &.showTick {
        text-align: center;
        padding-bottom: 14px;
        &::before {
          // right: 9px;
          // top: 25px;
          bottom: 8px;
          right: 0;
          left: 0;
          margin: auto;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background-size: 16px;
        }
      }

      &--active {
        &::after {
          width: 24px;
          height: 24px;
          margin-left: -13px;
          bottom: -9px;
          border-radius: 3px;
        }
      }
    }
  }
}
