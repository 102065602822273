@import "../../styles/variables";

.profile-section {
  padding: 25px;
  border: 1px solid $block-bg-color;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    span {
      color: $theme-color;
      font-weight: 600;
    }
    span.tag {
      display: inline-block;
      margin-top: 10px;
      padding: 4px 8px;
      border-radius: 3px;
      color: white;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      box-sizing: border-box;

      &.open {
        background-color: #e7f4e4;
        color: #48a463;
        border: 1.4px solid #2fb60d;
      }

      &.close {
        background-color: #f9dede;
        color: red;
        border: 1.4px solid red;
      }
    }
  }

  &__status-container {
    padding-bottom: 60px;
  }

  &__status-label {
    font-weight: 500;
  }

  &__status {
    font-weight: 500;
    &.not-applied {
      color: #c4c4c4;
    }

    &.not-complete {
      color: red;
    }

    &.complete {
      color: green;
    }
  }
}
