@import "../../styles/variables";

.label {
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
  font-size: $normal-text-size;
  position: relative;

  span {
    font-size: 14px;
  }

  &::after {
    content: "*";
    position: relative;
    right: -5px;
    color: red;
  }

  &.optional {
    &::after {
      display: none;
    }
  }
}
.input-style,
.gender-container {
  background-color: white;
  border: 1px solid #64729a;
  border-radius: 5px;
  padding: 0 15px;
  height: 50px;
  margin-top: 0;
  font-size: $normal-text-size;
  width: 100%;

  &:hover {
    box-shadow: 0 0 0 4px rgba(202, 146, 14, 0.18);
  }

  &:focus {
    outline: 0;
    background-color: #fff;
    border-color: #fad57f;
    box-shadow: 0 0 0 4px rgba(202, 146, 14, 0.18);
    border-width: 2px;
  }

  &:read-only {
    background-color: #f4f4f4;
  }

  &[type="file"] {
    padding: 11px;
  }

  &.upload-field {
    padding: 22px 11px;
    height: 70px;
  }
}

input {
  &#full_name {
    text-transform: uppercase;
  }
}

.gender-container {
  background-color: white !important;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 12px 0 0 0;

  & > div {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  input {
    width: 24px;
    height: 24px;
    margin: 1px 5px 0 0;
  }
}

.input-field-container {
  margin-bottom: 15px;
  position: relative;
  &.lock {
    &::after {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      right: 4px;
      top: 5px;
      margin-top: 31px;
      background-image: url("../../images/lock.png");
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: center;
    }

    input:hover {
      box-shadow: none;
      background-color: rgba(13, 12, 34, 0.05);
      cursor: not-allowed;
    }
  }
}

.input_error {
  border: 1px solid red;
}

.error_message {
  font-size: 14px;
  position: absolute;
  top: 0px;
  right: 0;
  color: red;
  line-height: normal;
  margin-top: 5px;

  &.upload {
    position: static;
  }
}

@media only screen and (max-width: 639px) {
  label {
    font-size: $mobile-text-size;

    span {
      font-size: 12px;
    }
  }

  .input-field-container {
    margin-bottom: 0;
  }

  .error_message {
    min-height: 20px;
    position: static;
    font-size: 12px;
    line-height: 20px;
    margin-top: 3px;
  }

  .input-style,
  .gender-container {
    font-size: $mobile-text-size;
    height: 40px;

    &[type="file"] {
      padding: 7.5px 11px;
    }

    .upload-field {
      padding: 7.5px 11px;
    }
  }

  .gender-container {
    padding: 7px 0 0 0;
  }
}

// for subject checkboxes
.subject {
  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    position: relative;

    input {
      width: 1px;
      height: 1px;
      display: inline;
      position: absolute;
    }
  }

  &__label {
    display: inline-block;
    padding: 5px 47px 6px 12px;
    border-radius: 5px;
    border: 1px solid #cfcfcf;
    font-size: 14px;
    position: relative;
    // margin-right: 30px;
    background: white;
    cursor: pointer;

    &::after {
      content: " ";
      position: absolute;
      width: 19px;
      height: 19px;
      border: 1px solid #a3a3a3;
      border-radius: 15px;
      right: 12px;
      top: 6px;
    }
  }
}

.filled-checkbox-input {
  position: absolute;
  opacity: 0;
}

.filled-checkbox-input:checked + .filled-checkbox-label {
  background-color: $theme-color;
  border-color: $theme-color;
  color: white;

  &::after {
    background-color: white;
    border: 0;
    width: 20px;
    height: 20px;
    top: 6.5px;
    background-image: url("../../images//tick_mark.png");
    background-size: cover;
  }
}

@media only screen and (max-width: 639px) {
  .subject {
    &__container {
      flex-direction: column;
      justify-content: space-between;
    }

    &__label {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .category {
    &__container {
      margin-bottom: 0;
    }
    &__documents-upload {
      padding: 21px 11px;

      & + .error_message {
        display: none;
      }
    }
  }

  .category__documents-upload + .error_message {
    display: none;
  }
}

// For Checkbox component
.checkbox-container {
  display: flex;
}

input.checkbox {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
}

.checkbox-label {
  height: 24px;
  line-height: 24px;
}

// For the category checkbox
.category {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    & > div:not(:last-child),
    & > p {
      margin-bottom: 10px;
    }
  }
  &__radio {
    height: 1px;
    width: 1px;
    &:hover,
    &:focus {
      box-shadow: none;
    }
    position: absolute;
    opacity: 0;
  }

  &__label {
    border: 1px solid $border-color;
    padding: 10px 20px;
    box-sizing: border-box;
    // display: grid;
    // grid-template-columns: 17px auto 20px;
    display: flex;
    border-radius: 5px;
    position: relative;
    font-size: 14px;
    grid-gap: 9px;
    color: $text-color;
    font-weight: 500;
    cursor: pointer;
    background: white;
    margin: 0;
    &::after {
      position: absolute;
      top: 10px;
      right: 20px;
      height: 18px;
      width: 18px;
      border: 1px solid #a3a3a3;
      border-radius: 11px;
      content: "";
    }

    // &:not(:last-child){
    //   margin-bottom:15px;
    // }

    &.clear-label {
      display: inline-block;
      padding: 2px 10px;
      font-size: 8px;
      border-radius: 20px;
      color: #e34c26;
      background-color: transparent;
      // color: $border-color;
      &::after {
        display: none;
      }
    }

    &.disable {
      background-color: #f2f2f2;
      border-color: #e0e0e0;
      // color:#C4C4C4;
      color: #c7c7c7;

      &::after {
        border-color: #cfcfcf;
        background-color: transparent;
      }
    }
  }
}

.category__radio:checked + .category__label {
  background-color: $block-bg-color;
  color: white;
  border-color: $block-bg-color;
  box-sizing: border-box;

  &::after {
    background-color: white;
    border-color: white;
    background-image: url("../../images/tick_mark.png");
    background-size: cover;
  }
}

.clear:checked + .clear-label {
  color: $border-color;
  background-color: transparent;
  color: #e34c26;
  border: 1px solid $border-color;
}
