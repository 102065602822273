@import "../../../styles/variables";

.faq {
  img {
    width: 100%;
    border-radius: 10px;
  }

  &__video {
    margin-top: 10px;
  }

  figure {
    max-width: 500px;
    margin: 0;
    background-color: #edeef0;
    padding: 30px 80px 30px 30px;
    margin-top: 20px;
    // margin-left: 40px;
    border-left: 7px solid $theme-color;

    &.big {
      max-width: 700px;
    }
  }
  figcaption {
    font-size: 14px;
    color: $text-color;
  }
}

.collapse {
  &__content {
    padding: 20px;
    // border-bottom: 2px solid #e4e4e4
    margin-top: -20px;
    background-color: #edeef0;
  }

  &__title {
    font-size: $normal-text-size;
  }

  &__text-container {
    margin-top: 20px;
  }

  &__text {
    &.small {
      font-size: 14px;
      line-height: 26px;
    }

    a {
      color: $theme-color;
      font-weight: 500;
    }
  }

  &__list-container {
    margin: 0;
    padding: 0;
  }

  &__list-item {
    list-style-position: inside;
    margin-top: 3px;
    list-style-type: square;
    color: $text-color;
  }
}

@media (max-width: 639px) {
  .faq {
    figure {
      padding: 10px;
    }
  }
}
