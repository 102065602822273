@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import "./styles/variables";

html {
  height: 100%;
}
body {
  background-color: $bg-color;
  font-family: "Poppins", sans-serif;
  height: 100%;
  overflow-x: hidden;
}
#root {
  height: calc(100% - 224px);
}
#root > div,
.main-footer-wrapper {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $title-color;
}

h1 {
  font-size: 35px;
  font-weight: 700;
  color: $title-color;
  margin-bottom: 35px;

  &.application-form__title {
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

p {
  font-size: $normal-text-size;
  font-weight: 400;
  color: $text-color;
  line-height: 28px;

  a {
    text-decoration: underline;
  }
}

a {
  cursor: pointer;
  color: #4285f4;
}
.button {
  display: inline-block;
  background-color: $theme-color;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  line-height: 37px;
  padding: 0 40px;
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  position: relative;

  a {
    color: white;
  }

  &:focus,
  &:hover {
    background-color: $twitter-bg-color;
    outline: 0;
  }

  &.disable {
    background-color: #a3a3a3;
    pointer-events: none;
  }

  &--grey {
    background-color: $block-bg-color;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: $bg-color;
  }
}

.next {
  padding-right: 33px;
  svg {
    margin-bottom: -3.1px;
    margin-left: 5px;
  }
}

.prev {
  padding-left: 33px;
  svg {
    margin-bottom: -3.1px;
    margin-right: 5px;
  }
}

.button.loading {
  &::after {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 10px;
    position: absolute;
    left: 10px;
    top: 8px;
    border: 3px solid blue;
    border-top: 3px solid white;

    animation: loading 2s infinite;
    -webkit-animation-timing-function: linear;
  }
}

@keyframes loading {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.grid-container {
  max-width: 67.5em;
}

.content-wrapper {
  padding: 50px 0 35px 0;
  // min-height: 400px;
}

.simple-page {
  &__para {
    margin-bottom: 10px;
  }
}

.home {
  &__para {
    margin-bottom: 40px;

    p {
      text-align: justify;
    }
  }

  &__sub-heading {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.register {
  &__list {
    padding: 0 0 0 20px;
    margin: 0;

    &__item {
      font-size: $normal-text-size;
      margin-bottom: 14px;
      color: $text-color;
      font-weight: 500;
      line-height: 28px;
      font-weight: 400;
    }
  }

  &__form {
    input {
      width: 100%;
    }

    .button {
      width: auto;
      border: 0;
      // margin-top: 15px;
    }
  }

  #backend-error {
    margin: 30px 0;
  }
}

.forgot {
  &__title {
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 35px;
  }
}

select {
  display: block;
  margin-top: 5px;
}

.error_message {
  font-size: 14px;
  color: red;
  position: absolute;

  &.upload {
    // position:static;
    top: 101px;
    line-height: normal;
    font-size: 11px;
    font-weight: 500;

    &.box {
      padding: 7px 15px;
      background-color: #f9dede;
      border-radius: 0 0 5px 5px;
      margin-left: 10px;
      width: calc(100% - 20px);
    }
  }
}

#backend-error,
.message-in-profile {
  font-weight: 500;
  font-size: 16px;
  padding: 30px;
  border-radius: 5px;
  display: none;
  &::first-letter {
    text-transform: uppercase;
  }

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
  
  &.success {
    background-color: #e7f4e4;
    color: #48a463;
    border: 1px solid #2fb60d;
  }

  &.failure {
    background-color: #f9dede;
    color: red;
    border: 1px solid red;
  }
}

.message-in-profile {
  p:not(:first-child),
  .button {
    margin-top: 20px;
  }
}

#server-error {
  color: red;
  font-weight: bold;
}

.forgot-password {
  // margin-top:6px;
  text-align: right;

  &.register {
    text-align: left;
    margin-top: 10px;
  }
  a {
    font-size: 16px;
    cursor: pointer;
    color: $text-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.imp-message {
  color: #ff3e3e;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.application {
  .button {
    margin-top: 30px;
  }

  hr {
    margin-top: 40px;
    border: 1px solid $title-color;
  }

  &__sub-heading {
    color: $title-color;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 15px;
    font-size: 18px;
  }

  &__list-container {
    padding: 0 0 0 16px;
    margin: 0;
  }

  &__list-item {
    color: $text-color;
    font-size: $normal-text-size;
    line-height: 28px;
    margin-bottom: 20px;
    font-weight: 500;

    span {
      padding-left: 10px;
      display: block;
    }
  }

  &__table {
    margin-top: 50px;
    border-collapse: collapse;
    border: 0.5px solid $border-color;
    width: 100%;
    &__row {
      // height:40px;
      display: grid;
      grid-template-columns: 2.5fr 0.8fr 6fr 2.7fr;
      th {
        font-weight: bold;
      }

      &.even {
        // background-color:#f1f1f1;
        border-top: 2px solid $block-bg-color;
        margin-top: 30px;
      }
    }

    &__item {
      font-size: $mobile-text-size;
      color: $text-color;
      text-align: left;
      border: 0.5px solid $border-color;
      padding: 8px 20px;
      border-collapse: collapse;
      // font-weight: 500;

      a {
        color: #4285f4;
      }

      &:nth-of-type(2) {
        text-align: right;
      }
    }

    &.for-choice {
      .application__table__row {
        grid-template-columns: 1.5fr 5fr 5fr 1.5fr;
      }

      .application__table__item {
        &:nth-of-type(2) {
          text-align: left;
        }
        &:last-child {
          background-color: rgba(235, 174, 31, 0.1);
        }
      }
    }
  }
}

.horizontal-rule {
  // margin:50px 0 70px 0;
  margin: 30px 0 50px 0;
  // border: 1px solid #cfcfcf;
  border: 3px solid rgba(202, 146, 14, 0.18);
  border-radius: 3px;
}

.paymentSuccess {
  background-color: #eaeaea;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 40px;
  display: inline-block;

  p:first-of-type {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 18px;
  }

  p {
    span {
      font-weight: 500;
    }
  }

  h3 {
    color: #00b800;
  }
}

.application-form {
  &__form {
    input {
      width: 100%;

      &#full_name {
        margin-bottom: 20px;
      }
    }

    // .category__sub-category__container{
    //   input{
    //     width:calc(100% - 40px)
    //   }
    // }

    & > div {
      margin-bottom: 20px;
    }

    &.for-choice {
      input {
        text-transform: uppercase;
      }
    }
  }

  hr {
    border: 1px solid #cfcfcf;
    margin: 0 0 40px 0;
  }

  p.sub-heading {
    font-weight: bold;
    color: $title-color;
    margin-bottom: 30px;
    font-size: $normal-text-size;
  }

  p.note {
    margin-top: -10px;
  }

  &.loading {
    position: relative;
    &:before {
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 10;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      display: inline-block;
      padding: 10px 15px;
      background-color: white;
      color: $title-color;
      content: "Loading...";
      z-index: 11;
      position: fixed;
      top: 0;
      margin: auto;
    }
  }
}

#photo_preview {
  margin-top: 30px;
  width: 170px;
  height: 218.56px;
  object-fit: fill; /*fill is set to pdfmake*/
  border: 1px solid $border-color;
  border-radius: 5px;
}

.green {
  color: #16a006 !important;
}

input#declaration {
  width: 24px;
  height: 24px;
  margin-left: 0;
}

.three-div {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
}

.two-div {
  display: grid;
  // grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(auto-fill, minmax(482px, 1fr));
  grid-gap: 30px;
}

.four-div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 30px;
}

.eight-four-div {
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-gap: 30px;
}

.category {
  &__sub-category__container {
    border: 1px solid $border-color;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: white;
    margin-bottom: 20px;

    // for clear
    & > div:last-child {
      margin-bottom: 10px !important;
    }

    // for element above clear || last option
    & > :nth-last-child(2) {
      margin-bottom: 10px !important;
    }

    &.no-margin {
      & div[class*="input-field"] {
        margin: 0 !important;
      }
    }
  }
  &__sub-group-name {
    font-size: 14px;
    color: $text-color;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.frame-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  iframe {
    width: 70vw;
    height: 70vh;
  }

  .close-frame {
    position: fixed;
    top: 40px;
    right: 40px;
    background-color: red;
  }
}

[class*="-cat"]:disabled + [class*="-cat-label"] {
  background-color: #f2f2f2;
  border-color: #e0e0e0;
  color: #c4c4c4;
  pointer-events: none;

  &::after {
    border-color: #cfcfcf;
    background-color: transparent;
  }
}

.counselling__para {
  span.category-name {
    font-weight: 700;
  }

  span.warn {
    color: red;
    font-weight: 500;
  }
}

.swal-button {
  background-color: $theme-color;
  &:not([disabled]):hover {
    background-color: $twitter-bg-color;
  }
  &:active {
    background-color: $theme-color;
  }
  &:focus {
    box-shadow: 0 0 0 4px rgba(202, 146, 14, 0.18);
  }
}

.detail-card {
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 10px;
  padding: 30px;

  &__section {
    display: flex;
    flex-direction: row;
  }

  &__subsection {
    padding: 20px 20px 0 20px;

    & > div {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__photo {
    // width: 170px;
    // height: 218.56px;
    width: 120px;
    height: 154.28px;
    object-fit: fill; /*fill is set to pdfmake*/
    border: 1px solid $border-color;
    border-radius: 5px;
    margin-right: 20px;
    margin-top: 20px;
  }

  &__label {
    font-weight: 500;
    padding-right: 20px;
    color: $title-color;
    text-decoration: underline;
  }

  &__categories {
    display: grid;
    grid-template-columns: auto;
  }
}

.acknowledgment_card {
  display: grid;
  grid-template-columns: 110px auto;
  grid-gap: 20px;
  border-bottom: 1px solid $block-bg-color;
  padding-bottom: 30px;

  &__title {
    &--small {
      font-size: 18px;
      color: $title-color;
      // margin-bottom:10px;
    }
    &--big {
      font-size: 20px;
      color: $title-color;
      font-weight: 500;
      // margin-bottom:10px;
    }
  }

  &__address {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.preview-info {
  background-color: white;
  border: 1px solid $border-color;
  margin: 30px 0 0 0;
  border-radius: 10px;

  table {
    width: 100%;
    p {
      padding: 8px 15px;
      border-bottom: 6px solid rgba(202, 146, 14, 0.18);
      border-radius: 0;
      font-weight: 500;
      margin-top: 10px;
    }

    hr {
      margin: 15px 0 0 0;
      border-radius: 0;
    }
  }

  tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    &.three-sections {
      grid-template-columns: 2fr 1fr 1fr;
    }

    &.four-sections {
      grid-template-columns: 0.8fr 1fr 0.8fr 1fr;
    }
  }

  td {
    padding: 9px 15px;
    font-size: 15px;
    color: $text-color;

    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }
}

#subject-error {
  position: absolute;
  bottom: -10px;
  left: 0;
  top: auto;
}

.pre-footer {
  p {
    margin-bottom: 50px;
  }
  .button-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin-right: 20px;

    // .button--grey {
    //   background-color: $block-bg-color;

    //   &:hover {
    //     background-color: $twitter-bg-color;
    //   }
    // }

    .button.single {
      background-color: $theme-color;

      &:hover,
      &:focus {
        background-color: $twitter-bg-color;
      }
    }
  }
}

.marks {
  &__container {
    border: 1px solid $border-color;
    border-radius: 5px;
    margin-bottom: 30px;
  }

  &__row {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__data {
    padding: 12px 20px;
    color: $text-color;
    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }
    &:not(.heading):not(:first-child) {
      padding: 0;
    }
    &.heading {
      font-weight: 500;
      color: $title-color;
      font-size: $mobile-text-size;
    }
    input {
      border: 0;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      color: $text-color;
      padding: 0 15px;

      &:hover,
      &:focus {
        border: 3px solid rgba(202, 146, 14, 0.18);
      }
    }

    .for-mobile {
      display: none;
    }
    .not-for-mobile {
      display: block;
    }
  }
}

.paymentPage {
  &__text {
    margin-bottom: 30px;
  }
}

.message {
  &__box {
    // background-color:#FFF5C9;
    background-color: #fff1d2;
    padding: 20px 30px;
    border-radius: 5px;
    margin-bottom: 80px;

    &.warn {
      // border: 1px solid #FDB81E;
      border: 1px solid red;
      // background-color:#f9dede;
      color: $text-color;
      a {
        color: inherit;
      }

      h3 {
        color: red;
      }
    }

    &.success {
      border: 1px solid #2fb60d;
      background-color: #e7f4e4;
      a {
        color: inherit;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: $title-color;
    margin: 10px 0;
  }

  &__list-container {
    margin: 0;
    padding: 0;
  }

  &__list-item {
    font-size: 16px;
    color: $text-color;
    text-transform: uppercase;
    margin: 15px 0;
    list-style-type: none;
    font-weight: 500;
  }
}

.indicator {
  height: 20px;
  width: 20px;
  // animation: blink 1s infinite;
  fill: $block-bg-color;
  // background-color:#142850; border-radius:20px; position: relative; display: grid; place-items: center;

  // &:after{
  //   content:''; background-color:#00a8cc; height:10px; width:10px; border-radius:5px; position:absolute; animation: blink 1s infinite;
  // }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.show_preview {
  display: block;
}
.hide_preview {
  display: none;
}

.show_cat {
  display: grid;
}
.hide_cat {
  display: none;
}

.four0four {
  padding: 40px 10px;
  text-align: center;
  &__image {
    max-width: 400px;
    margin-left: -15px;
  }

  &__title {
    text-align: center;
    margin: 30px 0;
    color: $title-color;
  }

  &__text {
    font-size: $normal-text-size;
    text-align: center;
    max-width: 700px;
  }
}

input.declaration-input,
div.declaration-input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $text-color;
  display: inline-block;
  width: 95%;
  border-radius: 0;
  height: auto;
  color: $text-color;
  font-weight: 600;
  padding: 0;
  text-transform: uppercase;
  transition: width 0.2s ease-in;
  position: relative;

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.button + .forgot-password {
  margin-top: 40px;
}

.choices-container {
  span > span {
    &:not(:last-child) {
      margin-right: 10px;
      &:after {
        content: ",";
      }
    }
  }
}

.choice_form_preview {
  border: 2px solid $block-bg-color;
  border-radius: 10px;
  padding: 30px;

  p {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 0;

    span {
      color: $title-color;
      padding-right: 20px;
    }
  }

  table {
    margin: 20px 0 0 0;
    border: 1px solid grey;
    border-collapse: collapse;
    tr {
      height: 40px;
    }
    td,
    th {
      width: 140px;
      padding: 5px 10px;
      border: 1px solid grey;
      text-align: center;
    }
    td {
      &:last-child {
        text-transform: uppercase;
      }
    }
  }
}

.tfw_ews_consideration {
  margin: "20px 0 0 0";
  min-height: "0";
  flex-direction: "row";
  justify-content: "flex-start";
}

#declaration-checkbox {
  width: 30px;
  margin-right: 12px;
  margin-top: -5px;
}
#declaration-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1023px) {
  #root {
    height: calc(100% - 328px);
  }
  .eight-four-div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 30px;
  }

  .profile-section {
    &__title {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 639px) {
  #declaration-checkbox {
    width: 30px;
    margin-right: 12px;
    margin-top: -5px;
  }
  #declaration-container {
    flex-direction: column;
  }
  #root {
    height: calc(100% - 142px);
  }
  h1 {
    font-size: 24px;
    margin-bottom: 12px;

    &.application-form__title {
      margin-bottom: 20px;
      span {
        display: block;
        font-size: $mobile-text-size;
      }
    }
  }

  p {
    font-size: $mobile-text-size;
    line-height: 26px;
  }
  .content-wrapper {
    padding: 20px 0;
  }

  .grid-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .two-div,
  .three-div,
  .four-div,
  .eight-four-div {
    grid-gap: 10px; /* changed from 20*/
  }

  .two-div,
  .three-div,
  .eight-four-div {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }

  .subject {
    &__container {
      flex-direction: column;
      justify-content: space-between;
      // min-height: 140px;
    }
  }
  #subject-error {
    bottom: -20px;
  }

  .error_message {
    min-height: 20px;
    position: static;
    font-size: 12px;
    line-height: 20px;
    margin-top: 3px;
  }

  .home {
    &__para {
      margin-bottom: 24px;
    }
  }

  .application {
    &__list-item {
      font-size: $mobile-text-size;
      line-height: 26px;
    }

    &__sub-heading {
      font-size: $mobile-text-size;
    }

    // .button {
    //   margin-top: 0;
    // }

    &__table {
      &__row {
        display: block;
      }

      &.for-choice {
        .application__table__row {
          margin-bottom: 40px;
          border-top: 3px solid $block-bg-color;
        }

        .application__table__item {
          display: inline-block;
          &:first-child {
            display: inline-block;
            width: 50px;
          }
          &:nth-child(2) {
            width: calc(100% - 50px);
          }

          &:nth-child(3) {
            width: calc(100% - 80px);
          }

          &:last-child {
            width: 80px;
            font-weight: 600;
          }
        }
      }

      &__item {
        &:first-child {
          display: none;

          &.heading {
            display: block;
            // grid-area: 1 / 1 / 1 / span 2;
            font-weight: 500;
            color: $title-color;
          }
        }

        &:nth-child(2) {
          width: 50px;
          display: inline-block;
          vertical-align: top;
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          border-top-width: 1px;
          margin-top: -0.5px;
        }

        &:nth-child(3) {
          width: calc(100% - 50px);
          display: inline-block;
        }

        &:last-child {
          display: none;

          &.prospectus {
            display: block;
            grid-column-start: 1;
            grid-column-end: span 2;
            // text-align:right;
            padding-left: 70px;
            // color:#4285F4;
          }
        }
      }
    }
  }
  // form {
  //   overflow-x: hidden;
  //   overflow-y:visible;
  // }
  .application-form {
    &__form {
      & > div {
        margin-bottom: 10px;
      }

      input {
        &#full_name {
          margin-bottom: 0;
        }
      }
    }

    hr {
      margin: 25px 0 20px 0;
    }
    .gender-container {
      padding: 9px 0 0 0;

      label {
        line-height: 26px;
      }
    }

    p.sub-heading {
      margin-bottom: 20px;
    }
  }

  label {
    font-size: $mobile-text-size;

    span {
      font-size: 12px;
    }
  }

  input,
  select,
  .gender-container {
    font-size: $mobile-text-size;
    height: 46px;
  }

  #photo_preview {
    margin-top: 0;
  }

  .forgot-password {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: normal;

    a {
      font-size: 14px;
    }
  }

  .register {
    &__list {
      &__item {
        font-size: $mobile-text-size;
      }
    }

    &__note {
      margin: 20px 0;
    }

    .button {
      margin-top: 0;
    }

    #backend-error {
      margin: 20px 0;
    }
  }

  .marks {
    &__container {
      margin-bottom: 20px;
      &:first-child {
        margin-top: 20px;
      }
    }

    &__row {
      display: flex;
      &.with-grid,
      &.with-small-grid {
        .marks__data {
          width: auto;
          &.heading {
            padding: 12px;
          }
          &:first-child {
            grid-area: 1 / 1 / 3 / 3;
            width: auto;
            border-bottom: 1px solid $border-color;
          }

          &:nth-child(2) {
            grid-area: 3 / 1 / 4 / 2;
          }

          &:nth-child(3) {
            grid-area: 3 / 2 / 4 / 3;
          }

          input {
            height: 46px;
          }
        }
      }

      &.with-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }

      &.with-small-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        .marks__data {
          padding: 0;
        }
      }
    }

    &__data {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 11px 0 11px 20px;

      &:nth-child(2),
      &:nth-child(3) {
        width: 90px;
      }
      &:first-child {
        width: calc(100% - 180px);
      }

      .for-mobile {
        display: block;
        font-weight: 400;
        font-size: 14px;
        margin-top: 10px;
      }

      .not-for-mobile {
        display: none;
      }
    }
  }

  #backend-error {
    padding: 20px 15px;
    font-size: 13px;
    line-height: normal;
  }

  // .button{
  //   // padding: 0 20px;
  //   &.loading{
  //     padding-left: 38px;
  //   }
  // }

  .button {
    &.prev {
      padding: 0 26px 0 20px;
    }

    &.next {
      padding: 0 20px 0 26px;
    }
  }

  .pre-footer {
    p {
      margin-bottom: 20px;
    }

    .button-grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      grid-gap: 20px;

      .pre-footer__navigator {
        grid-area: 2 / 1 / 2 / 1;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .message {
    &__box {
      padding: 10px 20px;
      margin-bottom: 30px;
      p {
        font-size: 13px;

        &.justify {
          text-align: justify;
        }
      }
    }

    &__title {
      font-size: 17px;
    }
    &__list-item {
      font-size: 13px;
    }
  }

  .error_message {
    &.upload {
      &.box {
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .detail-card {
    &__section {
      flex-direction: column;
    }

    &__subsection {
      padding: 20px 0 0 0;
    }
  }

  .acknowledgment_card {
    display: block;
    &__dte-logo {
      display: none;
    }
  }

  .four0four {
    padding: 40px 0;
    &__image {
      max-width: 300px;
    }
  }

  .profile-section {
    min-height: 250px;
    padding: 15px;

    &:first-of-type {
      margin-bottom: 20px;
    }

    &__status-container {
      padding-bottom: 15px;
    }
  }

  .preview-info {
    overflow-x: scroll;
  }
}

@import "./components/DocumentUpload/style.scss";
